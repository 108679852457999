import styles from './subcategory.module.css';
import { motion } from 'framer-motion';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedArticle } from '../../store/articleSlice';
import { Accordion } from '../index';

const SubCategory = ({ category }): JSX.Element => {

	const rendertype = () => {
		switch (category.type) {
			case 'articles':
				return <DropdownArticle category={category} />;
			case 'redirect':
				return <div>NO existe redirect</div>;
			case 'direct':
				return category.directUuidArticle ? (
					<div className={styles.container}>
						<Link to={`/article/${category.directUuidArticle}`}>
							<p>{category.name}</p>
						</Link>
					</div>
				) : (
					<div className={styles.removeThisClass}>
						<p>{category.name}*</p>
					</div>
				);
		}
	};

	return <>{rendertype()}</>;
};

export default SubCategory;

const DropdownArticle = ({ category }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const handleSelectArticle = async article => {
		await dispatch(setSelectedArticle(article));
		history.push(`/article/${article.uuid}`);
	};
	return (
		<Accordion title={category.name}>
			<motion.ul aria-label="support-reason" className={styles.container}>
				{category.articles &&
					category.articles.map(article => {
						return (
							<li key={article.name} onClick={() => handleSelectArticle(article)}>
								<p>{article.name}</p>
							</li>
						);
					})}
			</motion.ul>
		</Accordion>
	);
};
