import SubCategory from '../SubCategory/SubCategory';
import styles from './listsubcategory.module.css';
import { ISubcategories } from '../../store/articleSlice';

export interface IListSubcategoriesProps {
	subcategories: Array<ISubcategories>;
}

const ListSubcategory = ({ subcategories }: IListSubcategoriesProps) => {
	return (
		<div className={styles.container}>
			<ul aria-label="subcategory">
				{subcategories.map(category => {
					return (
						<li key={category.uuid}>
							<SubCategory category={category} />
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default ListSubcategory;
