export const EVENT: AnalyticsEvent = {
    navigation: {
        category: 'navigation',
        actions: {
            category: 'category',
            article: 'article',
            razon_soporte: 'ticket - razón de soporte',
            mis_tickets: 'mis tickets',
            ticket_detail: 'detalle ticket'
        }
    },
    click: {
        category: 'click',
        actions: {
            toogle_sub: 'toggle subcategory',
            toogle_escenario: 'toggle escenario'
        }
    },
    ticket: {
        category: 'ticket',
        actions: {
            razon_soporte_created: 'razón de soporte - creado',
            escenario_created: 'escenario - creado',
            razon_soporte_error: 'razón de soporte - error',
            escenario_error: 'escenario - error'
        }
    },
    search: {
        category: 'search',
        actions: {
            found: 'se encontraron resultados',
            not_found: 'no se encontraron resultados',
            error: 'error'
        }
    },
    feedback_article: {
        category: 'feedback-article',
        actions: {
            positive: 'positiva',
            negative: 'negativa'
        }
    },
    feedback_ticket: {
        category: 'feedback-ticket',
        actions: {
            positive: 'positiva',
            negative: 'negativa'
        }
    }
};

type Event = {
    category: string,
    actions: any
}

type AnalyticsEvent = {
    navigation: Event,
    click: Event,
    ticket: Event,
    search: Event,
    feedback_article: Event,
    feedback_ticket: Event
}
