import React, { useEffect } from 'react';
import styles from './ticketerror.module.css';
import errorC from './../../assets/images/error-connect.svg';
import { useHistory } from 'react-router-dom';
import useTitle from '../../hooks/useTitle';
import { pageview } from 'react-ga';
import { setStatusTicketInitial } from '../../store/ticketSlice';
import { useDispatch } from 'react-redux';

//TODO: mejorar componente
const TicketErrorPage = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	useTitle('Error - albo');

	useEffect(() => {
		dispatch(setStatusTicketInitial());
		pageview('/ticket-error');
	}, []);

	return (
		<div>
			<img src={errorC} alt="RateTicket albo error" />
			<h1 className={styles.title}>Hay un problema al enviar</h1>
			<p className={'size-12 ' + styles.text}>Vuelve a intentar en unos minutos.</p>
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<div className="fixed-bottom">
				<button className="btn-primary" onClick={() => history.push('/')}>
					Aceptar
				</button>
			</div>
		</div>
	);
};

export default TicketErrorPage;
