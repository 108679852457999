import styles from './label.module.css';

interface IPropsLabel {
	name: string;
	title: string;
	required: boolean | { value: any; message: string };
}

const Label = ({ name, title, required }: IPropsLabel) => {
	return (
		<label htmlFor={name} hidden={!title} className={styles.label}>
			{title}
			{required && <span style={{ color: 'red' }}>*</span>}
		</label>
	);
};

export default Label;
