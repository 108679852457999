import { useEffect, useState } from 'react';
import styles from './tickets.module.css';
import { motion } from 'framer-motion';
import {useDispatch, useSelector} from 'react-redux';
import {fetchTickets, selectTickets} from '../../store/ticketSlice';
import {event, pageview} from 'react-ga';
import {HeaderBack, ListTicket} from 'src/components';
import {EVENT} from "../../config/analytics";

export interface ITicket {
	open: Array<{ title: string; cdate: string; uuid: string }>;
	resolved: Array<{ title: string; cdate; uuid: string }>;
}

const TicketsPage = () => {
	const tickets = useSelector(selectTickets);
	const dispatch = useDispatch();
	const [typeOfTicket, setTypeOfTicket] = useState<'open' | 'resolved'>('open');

	const handleTypeOfTicket = (type: 'open' | 'resolved'): void => {
		setTypeOfTicket(type);
	};

	useEffect(() => {
		pageview('/tickets');
		event({category: EVENT.navigation.category, action: EVENT.navigation.actions.mis_tickets});
	}, []);
	useEffect(() => {
		dispatch(fetchTickets());
	}, []);

	return (
		<div className={styles.ticketPage}>
			<HeaderBack title="Mis tickets"/>
			<ul className={styles.typeOfTicket}>
				<motion.div
					className={styles.switch}
					animate={typeOfTicket}
					transition={{ duration: 0.3 }}
					variants={{
						open: { left: 0, right: 'auto' },
						resolved: { right: 0, left: 'auto' }
					}}
				/>
				<li className={typeOfTicket === 'open' ? styles.textWhite : ''} onClick={() => handleTypeOfTicket('open')}>
					<b>En proceso</b>
				</li>
				<li
					className={typeOfTicket === 'resolved' ? styles.textWhite : ''}
					onClick={() => handleTypeOfTicket('resolved')}
				>
					<b>Resueltos</b>
				</li>
			</ul>
			<ListTicket tickets={tickets[typeOfTicket]} />
		</div>
	);
};

export default TicketsPage;
