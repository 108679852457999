import { useHistory } from 'react-router-dom';
import React from 'react';
import styles from './headerback.module.css';

const HeaderBack = ({ title }: { title: string }): JSX.Element => {
	const history = useHistory();
	const goBack = () => {
		history.goBack();
	}
	return (
		<header className={styles.header}>
			<div className={styles.list}>
				<div className={styles.listItem}>
					<div className={styles.listContent}>
						<i className="ibon-chevron-left m-y-16" onClick={goBack} />
					</div>
				</div>
				<div className={styles.listItem}>
					<div className={styles.listContent}>
						<h1>{title}</h1>
					</div>
				</div>
			</div>
		</header>
	);
};

export default HeaderBack;
