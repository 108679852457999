import styles from './servererror.module.css';
import errorSearch from '../../assets/images/error-search.svg';
import React from 'react';

const ServerError = () => (
	<div className={styles.message}>
		<p>
			<b>Ups… no pudimos procesar tu solicitud</b>
		</p>
		<img src={errorSearch} alt="albo error search" />
		<p>
			<b>Intenta de nuevo en unos minutos.</b>
		</p>
	</div>
);

export default ServerError;
