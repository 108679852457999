import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchRefreshToken } from './userSlice';
import {getScenarioByID} from "../http/scenarioService";

export const fetchGetScenario = createAsyncThunk('comments/fetchGetScenario', async (uuidScenario: string) => {
	let response = await getScenarioByID(uuidScenario);
	if (response.type === fetchRefreshToken.fulfilled.type) {
		response = await getScenarioByID(uuidScenario);
	}
	return response;
});

export declare type TypeFormField = {
	"name": string,
	"type": string,
	"title": string,
	"placeholder"?: string,
	"validations": {},
	"_id"?: string,
	"uuid"?: string
}

const initialScenario: IScenarioState = {
	scenario: {
		status: 'initial',
		item: null
	},
};

const scenario: IScenarioState = JSON.parse(sessionStorage.getItem('store'))?.scenarios ?? initialScenario;

export interface IScenarioState {
	scenario: {
		status: 'initial' | 'pending' | 'success' | 'error';
		item?: {
			"_id"?: string,
			"uuidArticle": string,
			"title": string,
			"description": string,
			"form": {
				"title": string,
				"fields": Array<TypeFormField>
			},
			"uuid"?: string,
			"cDate"?: string,
			"active": boolean,
			"priority": number
		}
	},
}

export const scenarioSlice = createSlice({
	name: 'scenarios',
	initialState: scenario,
	reducers: {
		setScenario: (state, param) => {
			state.scenario.status = 'success';
			state.scenario.item = param.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchGetScenario.pending, (state) => {
			// state.scenario.status = 'pending';
			state.scenario.status = 'pending';
		});
		builder.addCase(fetchGetScenario.rejected, (state) => {
			// state.scenario.status = 'pending';
			state.scenario.status = 'error';
		});
		builder.addCase(
			fetchGetScenario.fulfilled,
			(state, action) => {
				state.scenario.status = 'success';
				state.scenario.item = action.payload;
			}
		);

	}
});

// Action creators are generated for each case reducer function
export const { setScenario } = scenarioSlice.actions;

export const selectScenario = (state: { scenarios: IScenarioState }) => state.scenarios.scenario;

export default scenarioSlice.reducer;
