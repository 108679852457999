import React, { useEffect } from 'react';
import styles from './ticketsuccess.module.css';
import { useHistory } from 'react-router-dom';
import useTitle from '../../hooks/useTitle';
import { pageview } from 'react-ga';
import { useDispatch } from 'react-redux';
import { setStatusTicketInitial } from '../../store/ticketSlice';

const TicketSuccessPage = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	useTitle('Éxito - albo');

	useEffect(() => {
		dispatch(setStatusTicketInitial());
		pageview('/ticket-success');
	}, []);

	return (
		<div className={styles.container}>
			<span className={styles.success}>
				<i className="ibon-check" />
			</span>
			<div className="text-center">
				<h1 className={styles.title}>Tendrás una respuesta en menos de 15 minutos</h1>
				<p className={styles.text}>
					Nuestros especialistas están revisando tu solicitud. <br />
					¡Tú eres lo más importante para nosotros!
				</p>
			</div>
			<div className="fixed-bottom m-16">
				<button className="btn-primary" onClick={() => history.push('/')}>
					Finalizar
				</button>
			</div>
		</div>
	);
};

export default TicketSuccessPage;
