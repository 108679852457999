import React from 'react';
import styles from './thumbnails.module.css';

const Thumbnails = ({ files = [], removeFile }) => {
	return (
		<>
			{files.length > 0 && (
				<ul className="m-b-16">
					{files.map((item, index) => {
						return (
							<div key={index + item.type} className={styles.container}>
								<img src={item.file} alt={item.name} className={styles.thumbnail} />
								<p>{item.name}</p>
								<i role="remove" className="ibon-close" onClick={() => removeFile(index)} />
							</div>
						);
					})}
				</ul>
			)}
		</>
	);
};

export default Thumbnails;
