import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchRefreshToken } from './userSlice';
import { feedbackArticle, getArticle, getSubcategory, searchArticle } from '../http/articleService';
import { getTaxonomy } from '../http/articleService';
import {event} from "react-ga";
import {EVENT} from "../config/analytics";

export const fetchGetTaxonomy = createAsyncThunk('comments/fetchGetTaxonomy', async (_, thunkAPI) => {
	let response = await getTaxonomy();
	if (response.type === fetchRefreshToken.fulfilled.type) {
		response = await getTaxonomy();
	}
	return response.data;
});
export const fetchFeedbackArticle = createAsyncThunk(
	'comments/fetchFeedbackArticle',
	async (
		{
			customerUuid,
			articleUuid,
			rate,
			comments
		}: { customerUuid: string; articleUuid: string; rate: boolean; comments?: string },
		thunkAPI
	) => {
		let response = await feedbackArticle(customerUuid, articleUuid, rate, comments);
		if (response.type === fetchRefreshToken.fulfilled.type) {
			response = await feedbackArticle(customerUuid, articleUuid, rate, comments);
		}
		return response.data;
	}
);
export const fetchGetSubcategory = createAsyncThunk(
	'comments/fetchGetSubcategory',
	async (category_uuid: string, { getState }) => {
		// @ts-ignore
		const category = getState().article.allSubcategories.find(category => category.category_uuid === category_uuid);
		if (category) {
			return category;
		} else {
			let response = await getSubcategory(category_uuid);
			if (response.type === fetchRefreshToken.fulfilled.type) {
				response = await getSubcategory(category_uuid);
			}
			return response.data;
		}
	}
);

export const fetchGetArticle = createAsyncThunk('comments/fetchGetArticle', async (uuid: string, { getState }) => {
	// @ts-ignore
	const article = getState().article.allArticles.find(category => category.article_uuid === uuid);
	if (article) {
		return article;
	} else {
		let response = await getArticle(uuid);
		if (response.type === fetchRefreshToken.fulfilled.type) {
			response = await getArticle(uuid);
		}
		return response.data;
	}
});

export const fetchSearchArticle = createAsyncThunk('comments/fetchSearchArticle', async (query: string, thunkAPI) => {
	let response = await searchArticle(query);
	if (response.type === fetchRefreshToken.fulfilled.type) {
		response = await searchArticle(query);
	}
	console.log({response})
	if (response.data.length === 0) {
		event({category: EVENT.search.category, action: EVENT.search.actions.not_found, label:  query})
	} else {
		event({category: EVENT.search.category, action: EVENT.search.actions.found, label:  query})
	}
	return response.data;
});

const initialArticle: IArticleState = {
	taxonomy: {
		status: 'initial',
		items: []
	},
	selectedCategory: {
		active: null,
		icon: null,
		order: null,
		status: null,
		cdate: null,
		name: null,
		path: null,
		uuid: null,
		_id: null
	},
	article: {
		status: 'initial',
		item: {
			article: null,
			active: null,
			cdate: null,
			name: null,
			path: null,
			udate: null,
			uuid: null,
			uuidCategory: null,
			_id: null,
			scenarios: [],
			carousel: []
		}
	},
	resultArticleSearch: [],
	loading: false,
	serverError: false,
	subcategories: {
		status: 'initial',
		items: []
	},
	allSubcategories: [],
	articleFeedback: {
		status: 'initial'
	},
	selectedArticle: {},
	allArticles: []
};

const article: IArticleState = JSON.parse(sessionStorage.getItem('store'))?.article ?? initialArticle;

export interface IArticleState {
	taxonomy: {
		status: 'initial' | 'pending' | 'success' | 'error';
		messageError?: string;
		items: Array<ITaxonomy>;
	};
	selectedCategory: ITaxonomy;
	article: {
		status: 'initial' | 'pending' | 'success' | 'error';
		messageError?: string;
		item: IArticle;
	};
	resultArticleSearch: Array<IArticle>;
	loading: boolean;
	serverError: boolean;
	subcategories: {
		status: 'initial' | 'pending' | 'success' | 'error';
		messageError?: string;
		items: Array<ISubcategories>;
	};
	allSubcategories: Array<{
		category_uuid: string;
		items: Array<ISubcategories>;
	}>;
	articleFeedback: {
		status: 'initial' | 'pending' | 'success' | 'error';
		messageError?: string;
	};
	selectedArticle: {
		uuid?: string;
		name?: string;
	};
	allArticles: Array<{ article_uuid: string; item: IArticle }>;
}

export interface ITaxonomy {
	active: boolean;
	cdate: string;
	icon: string;
	name: string;
	order: number;
	path: string;
	status: string;
	uuid: string;
	_id: string;
}

export interface ISubcategories {
	_id: string;
	uuid: string;
	uuidTaxonomy: string;
	path: string;
	name: string;
	active: boolean;
	order: number;
	type: string;
	cDate: string;
	articles?: Array<{ name: string; uuid: string; _id: string }>;
	directUuidArticle?: string;
}

export interface IArticle {
	active: boolean;
	article: string;
	cdate: string;
	name: string;
	path: string;
	udate: string;
	uuid: string;
	uuidCategory: string;
	_id: string;
	scenarios: Array<{ title; string; uuid: string; name: string; form: any; description: string }>;
	carousel: Array<{order: number, url: string}>
}

export const articleSlice = createSlice({
	name: 'article',
	initialState: article,
	reducers: {
		setArticle: (state, param) => {
			state.article = param.payload;
		},
		setResultArticle: (state, param) => {
			state.resultArticleSearch = param.payload;
		},
		setLoadingArticle: (state, param: PayloadAction<boolean>) => {
			state.loading = param.payload;
		},
		setServerErrorArticle: (state, param: PayloadAction<boolean>) => {
			state.serverError = param.payload;
		},
		setCategorySelected: (state, param: PayloadAction<ITaxonomy>) => {
			state.selectedCategory = param.payload;
		},
		clearSubcategories: state => {
			state.subcategories.status = 'initial';
			state.subcategories.items = [];
		},
		setSelectedArticle: (state, param: PayloadAction<{ uuid: string; name: string }>) => {
			state.selectedArticle = param.payload;
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchSearchArticle.fulfilled, (state, action: PayloadAction<Array<IArticle>>) => {
			state.resultArticleSearch = action.payload;
			state.loading = false;
		});
		builder.addCase(fetchSearchArticle.rejected, (state, action) => {
			state.serverError = true;
			state.loading = false;
			console.log({action})
			console.log('hola mundo')
			event({category: EVENT.search.category, action: EVENT.search.actions.error, label:  action.meta.arg})
		});
		builder.addCase(fetchSearchArticle.pending, (state, action) => {
			state.serverError = false;
			state.loading = true;
			state.resultArticleSearch = [];
		});
		builder.addCase(fetchGetArticle.pending, (state, action: PayloadAction<IArticle>) => {
			state.article.status = 'pending';
		});
		builder.addCase(
			fetchGetArticle.fulfilled,
			(state, action: PayloadAction<IArticle | { article_uuid: string; item: IArticle }>) => {
				state.article.status = 'success';

				if ('article_uuid' in action.payload) {
					state.article.item = action.payload.item;
				} else {
					state.allArticles = [...state.allArticles, { item: action.payload, article_uuid: action.payload.uuid }];
					state.article.item = action.payload;
				}
			}
		);
		builder.addCase(fetchGetArticle.rejected, state => {
			state.article.status = 'error';
		});
		//   -------------start taxonomy---------------
		builder.addCase(fetchGetTaxonomy.fulfilled, (state, action: PayloadAction<Array<ITaxonomy>>) => {
			state.taxonomy.status = 'success';
			state.taxonomy.items = action.payload;
		});
		builder.addCase(fetchGetTaxonomy.pending, (state, action) => {
			state.taxonomy.status = 'pending';
			state.taxonomy.items = action.payload;
			state.taxonomy.messageError = null;
		});
		builder.addCase(fetchGetTaxonomy.rejected, (state, action) => {
			console.error('action: ', action);
			state.taxonomy.status = 'error';
			// TODO: manejar mejor errores
			state.taxonomy.messageError = action?.error?.message;
		});
		//   -------------end taxonomy---------------
		//   -------------start getSubcategory---------------
		builder.addCase(
			fetchGetSubcategory.fulfilled,
			(
				state,
				action: PayloadAction<Array<ISubcategories> | { category_uuid: string; items: Array<ISubcategories> }>
			) => {
				state.subcategories.status = 'success';
				if (Array.isArray(action.payload)) {
					state.subcategories.items = action.payload;
					state.allSubcategories = [
						...state.allSubcategories,
						{ items: action.payload, category_uuid: action.payload[0].uuidTaxonomy }
					];
				} else {
					state.subcategories.items = action.payload.items;
				}
			}
		);
		builder.addCase(fetchGetSubcategory.pending, (state, action: PayloadAction<Array<ISubcategories>>) => {
			state.subcategories.status = 'pending';
			state.subcategories.items = [];
		});
		builder.addCase(fetchGetSubcategory.rejected, (state, action) => {
			state.subcategories.status = 'error';
			// state.subcategories = action.payload;
		});
		//   -------------end getSubcategory---------------

		//   -------------start taxonomy---------------
		builder.addCase(fetchFeedbackArticle.fulfilled, (state, action) => {
			state.articleFeedback.status = 'success';
		});
		builder.addCase(fetchFeedbackArticle.pending, state => {
			state.articleFeedback.status = 'pending';
		});
		builder.addCase(fetchFeedbackArticle.rejected, (state, action) => {
			state.articleFeedback.status = 'error';
		});
		//   -------------end taxonomy---------------
	}
});

// Action creators are generated for each case reducer function
export const {
	setArticle,
	setLoadingArticle,
	setServerErrorArticle,
	setResultArticle,
	setCategorySelected,
	clearSubcategories,
	setSelectedArticle
} = articleSlice.actions;

export const selectArticle = (state: { article: IArticleState }) => state.article.article;
export const selectResultArticleSearch = (state: { article: IArticleState }) => state.article.resultArticleSearch;
export const selectServerErrorArticle = (state: { article: IArticleState }) => state.article.serverError;
export const selectLoadingArticle = (state: { article: IArticleState }) => state.article.loading;
export const selectTaxonomy = (state: { article: IArticleState }) => state.article.taxonomy;
export const selectSubcategories = (state: { article: IArticleState }) => state.article.subcategories;
export const selectSelectedCategory = (state: { article: IArticleState }) => state.article.selectedCategory;
export const selectAllSubcategories = (state: { article: IArticleState }) => state.article.allSubcategories;
export const selectArticleFeedback = (state: { article: IArticleState }) => state.article.articleFeedback;
export const selectSelectedArticle = (state: { article: IArticleState }) => state.article.selectedArticle;

export default articleSlice.reducer;
