import { httpClientAuth } from './httpClient';
const service = process.env.REACT_APP_API_URL_TICKETS;

// TICKET
const getTickets = async (): Promise<any> => {
	return await httpClientAuth(`${service}/tickets`);
};

const getTicket = async (ticket_uuid: string): Promise<any> => {
	return await httpClientAuth(`${service}/tickets/${ticket_uuid}`);
};

const createTicket = async (body): Promise<any> => {
	return await httpClientAuth(`${service}/tickets`, { body });
};

const ticketFeedBack = async ({ ticket_uuid, ...body }): Promise<any> => {
	return await httpClientAuth(`${service}/tickets/${ticket_uuid}/feedback`, { body });
};

//COMMENTS
// TODO: anidar la url dentro de tickets
const getComments = async (ticket_uuid): Promise<any> => {
	return await httpClientAuth(`${service}/comments/${ticket_uuid}`);
};

const sendComment = async ({ ticket_uuid, body }): Promise<any> => {
	return await httpClientAuth(`${service}/tickets/${ticket_uuid}/comments`, { body });
};

export { getTickets, getTicket, createTicket, getComments, sendComment, ticketFeedBack };
