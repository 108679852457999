import { configureStore } from '@reduxjs/toolkit';
import articleSlice, {IArticleState} from './articleSlice';
import userSlice, {IUser} from './userSlice';
import ticketsSlice, {ITickets} from './ticketSlice';
import commentsSlice, {IResponseComments} from './commetSlice';
import scenariosSlice, {IScenarioState} from "./scenarioSlice";

export type StoreApp = {
	article: IArticleState,
	tickets: ITickets,
	user: IUser,
	comments: IResponseComments,
	scenario: IScenarioState,
}

const rootReducer = {
	article: articleSlice,
	tickets: ticketsSlice,
	user: userSlice,
	comments: commentsSlice,
	scenarios: scenariosSlice
};

const store = configureStore({
	reducer: rootReducer,
	devTools: !!process.env.REACT_APP_REDUX_TOOL
});

export type AppDispatch = typeof store.dispatch;

export default store;