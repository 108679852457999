import styles from "../ticketdetail.module.css";
import {createMarkup, timeWithFormat} from "../../../utils";

const TicketInfo = ({ticket}) => {

    return(
        <>
            <div className={`m-y-16 ${styles.customer}`}>
                <div dangerouslySetInnerHTML={createMarkup(ticket.ticket?.content)} />
                <span className={styles.time}>{timeWithFormat(ticket.ticket.cdate)}</span>
            </div>
            <div className={styles.containerImg}>
                {ticket?.ticket?.filesSigned.map(file => {
                    return <img key={file} className={styles.thumbnail} src={file} alt="file" loading="lazy" />;
                })}
            </div>
        </>
    )
}

export default TicketInfo;
