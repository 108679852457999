export interface IPropsOptions {
	options: Array<{ value: string; label: string }>;
}

export const renderOptions = ({ options }: IPropsOptions) => {
	return options.map(child => {
		return (
			<option key={child.value} value={child.value}>
				{child.label}
			</option>
		);
	});
};
