import { motion } from 'framer-motion';
import styles from './errorform.module.css';

const transition = {
	duration: 0.5
};
const variantsContainerError = {
	enter: {
		height: '31px',
		transition
	},
	exit: {
		height: 0,
		transition
	}
};

const variantsMessage = {
	enter: {
		opacity: 1,
		transition
	},
	exit: {
		opacity: 0,
		transition
	}
};

const ErrorForm = ({ errors, name }) => {
	return (
		<>
			{errors[name] && (
				<motion.div
					animate={errors[name] ? 'enter' : 'exit'}
					initial="exit"
					exit="exit"
					variants={variantsContainerError}
					className={`${styles.errorMessage} ${errors[name]?.type === 'required' ? styles.red : ''} `}
				>
					{errors[name] && (
						<motion.span animate={errors[name] ? 'enter' : 'exit'} initial="exit" exit="exit" variants={variantsMessage}>
							{errors[name].message}
						</motion.span>
					)}
				</motion.div>
			)}
		</>
	);
};

export default ErrorForm;
