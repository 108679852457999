import { getAttachmentSigned } from '../http/attachmentsService';
import { IDataUser } from '../store/userSlice';
import { IRequestTicket } from '../store/ticketSlice';

export const getFileUrlSigned = async response => {
	let files = [];
	if (response.response === 'ok' && response.data.files) {
		files = await Promise.all(
			response.data.files.map(async url => {
				const file = await getAttachmentSigned({ url });
				return file.data.signedurl;
			})
		);
	}
	return files;
};

export const dateWithFormat = (date: string): string => {
	return new Date(date).toLocaleString('es-MX', { dateStyle: 'long' });
};

export const timeWithFormat = (date: string): string => {
	return new Date(date).toLocaleString('es-MX', { hour: '2-digit', minute: '2-digit', hour12: true });
};

export const getQueryParamsFromUrl = (): IDataUser => {
	let query = new URLSearchParams(window.location.search);
	const { email, name, lastName, secondLastName, uuid, tokenGcm, ticket_uuid } = Object.fromEntries(query.entries());
	return { email, name, lastName, secondLastName, uuid, tokenGcm, ticket_uuid };
};

export function createMarkup(text) {
	return { __html: text };
}

export function buildBodyTicket({
	customer,
	article,
	fields,
	scenarioId,
	files,
	extra
}: {
	customer;
	article;
	fields?;
	scenarioId?;
	files?;
	extra?: any;
}): IRequestTicket {
	const createSupportReason = text =>
		text
			.toLowerCase()
			.replace(/ /gi, '_')
			.replace(/¿/gi, '')
			.replace(/\?/gi, '')
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '');
	const body: IRequestTicket = {
		customer: {
			name: customer.name
		},
		ticket: {
			fields,
			uuidCategory: article.uuidCategory,
			nameCategory: article.name,
			supportReason: createSupportReason(article.name),
			supportName: article.name,
			...extra
		}
	};
	if (fields) {
		body.ticket.fields = fields;
	}
	if (scenarioId) {
		body.ticket.scenarioId = scenarioId;
	}
	if (files) {
		body.ticket.files = files;
	}

	return body;
}
