import styles from "../ticketdetail.module.css";
import {ErrorForm, InputWhitThumbnail} from "../../../components";
import {useForm} from "react-hook-form";
import {removeDataInfoFromFileBase64} from "../../../utils/files";
import {fetchSendComment} from "../../../store/commetSlice";
import {useDispatch} from "react-redux";

const FormMessage = ({ticket_uuid}) => {
    const dispatch = useDispatch();
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
        reset,
        setValue,
        control
    } = useForm();
    const filesWatch = watch('files');

    const sendMessage = async (message) => {
        let files;
        if (message.files) {
            files = removeDataInfoFromFileBase64(message.files);
        }
        await dispatch(fetchSendComment({ ticket_uuid, files, message: message.message }));
        reset();
    };


    return(
        <form onSubmit={handleSubmit(sendMessage)}>
            <label htmlFor="message">¿Quieres agregar algo más?</label>
            <input
                placeholder="Mensaje..."
                id="message"
                type="text"
                {...register('message', { required: { value: true, message: 'El mensaje es requerido' } })}
                className={errors?.message?.type === 'required' ? styles.required : ''}
            />
            <ErrorForm errors={errors} name="message" />
            <InputWhitThumbnail name="files" files={filesWatch} control={control} setValue={setValue} />
            <button className="btn-primary" type="submit">
                Enviar
            </button>
        </form>
    )
}

export default FormMessage;
