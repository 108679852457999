import alboLoading from '../../assets/images/loadingFullScreen.gif';
import React from 'react';
import styles from './loader.module.css';

const Loader = ({ height = 100 }: { height?: number }) => {
	return (
		<div className={`center ${styles.loading}`} style={{ height: `${height}vh` }}>
			<img src={alboLoading} alt="albo loading" />
		</div>
	);
};

export default Loader;
