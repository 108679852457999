import React from 'react';
import styles from './header.module.css';
import logo from '../../assets/images/UI-logo.svg';
import { useHistory } from 'react-router-dom';

function Header({ showLogo = true, goBack = false }: { showLogo?: boolean; goBack?: boolean }) {
	const history = useHistory();
	return (
		<>
			<div className={styles.container} style={goBack ? { display: 'flex' } : { display: 'block' }}>
				{goBack && <i className="ibon-chevron-left" onClick={() => history.goBack()} />}
				<div className={goBack ? styles.titleGoBack : styles.title}>
					<h3 onClick={() => history.push('/')}>Centro de ayuda</h3>
					{showLogo && <img src={logo} alt="Logo albo" />}
				</div>
			</div>
		</>
	);
}

export default Header;
