import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form';
import { IPropsOptions, renderOptions } from './options';

interface IPropsSelect {
	name: string;
	register: UseFormRegister<any>;
	validations: RegisterOptions;
	options: IPropsOptions;
}

const Select = ({ name, register, validations, options }: IPropsSelect) => {
	return (
		<select name={name} {...register(name, validations)}>
			{renderOptions(options)}
		</select>
	);
};

export default Select;
