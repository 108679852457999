import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTickets, getTicket, createTicket, ticketFeedBack } from '../http/ticketsService';
import { fetchRefreshToken } from './userSlice';
import { getFileUrlSigned } from '../utils';
import {event} from "react-ga";
import {EVENT} from "../config/analytics";

export const fetchTickets = createAsyncThunk('tickets/fetchTickets', async (_, thunkAPI) => {
	let response = await getTickets();
	if (response.type === fetchRefreshToken.fulfilled.type) {
		response = await getTickets();
	}
	return response.data;
});

export const fetchTicket = createAsyncThunk('tickets/fetchTicket', async (ticket_uuid: string, thunkAPI) => {
	let response = await getTicket(ticket_uuid);
	if (response.type === fetchRefreshToken.fulfilled.type) {
		response = await getTicket(ticket_uuid);
	}
	response.data.filesSigned = await getFileUrlSigned(response);
	return response.data;
});

export const fetchCreateTicket = createAsyncThunk(
	'tickets/fetchCreateTicket',
	async ({body, scenario}: {body: IRequestTicket, scenario?: string}, _) => {
		let response = await createTicket(body);
		if (response.type === fetchRefreshToken.fulfilled.type) {
			response = await createTicket(body);
		}
		return {
			response: {
				...response.data,
				...body.ticket
			},
			scenario
		};
	}
);

export const fetchFeedbackTicket = createAsyncThunk(
	'tickets/fetchFeedbackTicket',
	async (body: { ticket_rate: number; feedback: string; ticket_uuid: string }, thunkAPI) => {
		let response = await ticketFeedBack(body);
		if (response.type === fetchRefreshToken.fulfilled.type) {
			response = await ticketFeedBack(body);
		}
		return response.data;
	}
);

export interface IRequestTicket {
	customer: {
		name: string;
	};
	ticket: {
		description?: string;
		title?: string;
		supportReason: string;
		supportName?: string;
		files?: Array<{
			file: string;
			type: string;
		}>;
		uuidCategory: string;
		nameCategory: string;
		scenarioId?: string;
		fields?: Array<{ id: string; input: { value: any; type: string } }>;
	};
}

const ticketsInit: ITickets = {
	tickets: {
		resolved: [],
		open: [],
		meta: null,
		status: 'initial'
	},
	selectedTicket: {
		status: 'initial',
		ticket: {
			cdate: null,
			content: null,
			files: [],
			filesSigned: [],
			status: null,
			support_reason: null,
			ticket_uuid: null,
			title: null
		}
	},
	ticket: {
		cdate: null,
		content: null,
		files: [],
		filesSigned: [],
		status: null,
		support_reason: null,
		ticket_uuid: null,
		title: null
	},
	selectedUuidTicket: null,
	ticketCreated: {
		status: 'initial'
	},
	ticketRate: {
		status: 'initial'
	}
};
const tickets: ITickets = JSON.parse(sessionStorage.getItem('store'))?.tickets ?? ticketsInit;

export interface ITicket {
	cdate: string;
	content?: string;
	files?: [];
	filesSigned?: [];
	status: string;
	support_reason?: string;
	ticket_uuid: string;
	title: string;
	ticket_rate?: number;
}
export interface IMeta {
	close: number;
	resolving: number;
	total: number;
}
export interface ITickets {
	tickets: IResponseTickets;
	selectedTicket: {
		status?: 'initial' | 'pending' | 'success' | 'error';
		messageError?: string;
		ticket: ITicket;
	};
	ticket: ITicket;
	selectedUuidTicket: string;
	ticketCreated: {
		status?: 'initial' | 'pending' | 'success' | 'error';
		messageError?: string;
	};
	ticketRate: {
		status?: 'initial' | 'pending' | 'success' | 'error';
		messageError?: string;
	};
}
export interface IResponseTickets {
	meta: IMeta;
	resolved: ITicket[];
	open: ITicket[];
	status?: 'initial' | 'pending' | 'success' | 'error';
	messageError?: string;
}

export const ticketsSlice = createSlice({
	name: 'tickets',
	initialState: tickets,
	reducers: {
		selectUuidTicket: (state, param: PayloadAction<string>) => {
			//state.selectedUuidTicket = param.payload;
		},
		clearTicketSelected: (state, param: PayloadAction<string>) => {
			state.selectedTicket = {
				status: 'initial',
				ticket: {
					cdate: null,
					content: null,
					files: [],
					filesSigned: [],
					status: null,
					support_reason: null,
					ticket_uuid: null,
					title: null
				}
			};
		},
		setStatusTicketInitial: state => {
			state.ticketCreated.status = 'initial';
		}
	},
	extraReducers: builder => {
		//   -------------start tickets---------------
		builder.addCase(fetchTickets.fulfilled, (state, action) => {
			state.tickets.status = 'success';
			state.tickets.meta = action.payload.meta;

			state.tickets.open = action.payload.tickets.open;
			state.tickets.resolved = action.payload.tickets.resolved;
		});
		builder.addCase(fetchTickets.pending, (state, action: PayloadAction<IResponseTickets>) => {
			state.tickets.status = 'pending';
			state.tickets.messageError = null;
		});
		builder.addCase(fetchTickets.rejected, (state, action) => {
			state.tickets.status = 'error';
			// state.tickets.messageError = '';
		});
		//   -------------end tickets---------------
		//   -------------start ticket---------------

		builder.addCase(fetchTicket.fulfilled, (state, action: PayloadAction<ITicket>) => {
			state.selectedTicket.status = 'success';
			state.selectedTicket.ticket = action.payload;
		});
		builder.addCase(fetchTicket.pending, (state, action: PayloadAction<ITicket>) => {
			state.selectedTicket.status = 'pending';
		});
		builder.addCase(fetchTicket.rejected, (state, action) => {
			state.selectedTicket.status = 'error';
		});
		//   -------------end ticket---------------

		builder.addCase(fetchCreateTicket.fulfilled, (state, action: PayloadAction<{response: {supportName: string, ticket_uuid: string}, scenario: string | undefined}>) => {
			state.ticketCreated.status = 'success';
			if (!action.payload.scenario) {
				event({category: EVENT.ticket.category, action: EVENT.ticket.actions.razon_soporte_created, label: action.payload.response.supportName})
			} else {
				event({category: EVENT.ticket.category, action: EVENT.ticket.actions.escenario_created, label: `${action.payload.response.supportName} - ${action.payload.scenario}`})
			}
		});
		builder.addCase(fetchCreateTicket.pending, (state, action: PayloadAction<ITicket>) => {
			// console.log(action.payload);
			state.ticketCreated.status = 'pending';
		});
		builder.addCase(fetchCreateTicket.rejected, (state, action) => {
			console.log({action});
			state.ticketCreated.status = 'error';
			if (!action?.meta?.arg?.scenario) {
				event({category: EVENT.ticket.category, action: EVENT.ticket.actions.razon_soporte_error, label: action?.meta?.arg?.body?.ticket?.supportName})
			} else {
				event({category: EVENT.ticket.category, action: EVENT.ticket.actions.escenario_error, label: `${action?.meta?.arg?.body?.ticket?.supportName} - ${action?.meta?.arg?.scenario}`})
			}
		});
		builder.addCase(fetchFeedbackTicket.fulfilled, (state, action: PayloadAction<ITicket>) => {
			// console.log(action.payload);
			state.ticketRate.status = 'initial';
		});
		builder.addCase(fetchFeedbackTicket.pending, (state, action: PayloadAction<ITicket>) => {
			state.ticketRate.status = 'pending';
		});
		builder.addCase(fetchFeedbackTicket.rejected, state => {
			state.ticketRate.status = 'error';
		});
	}
});

// Action creators are generated for each case reducer function
export const { selectUuidTicket, clearTicketSelected, setStatusTicketInitial } = ticketsSlice.actions;

export const selectTickets = (state: { tickets: ITickets }) => state.tickets.tickets;
export const selectSelectedTicket = (state: { tickets: ITickets }) => state.tickets.selectedTicket;
export const selectedUuidTicket = (state: { tickets: ITickets }) => state.tickets.selectedUuidTicket;
export const selectTicketCreated = (state: { tickets: ITickets }) => state.tickets.ticketCreated;
export const selectTicketRate = (state: { tickets: ITickets }) => state.tickets.ticketRate;

export default ticketsSlice.reducer;
