import { useState } from 'react';
import { motion } from 'framer-motion';
import styles from './accordion.module.css';

const Accordion = ({ children, title }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<motion.div
			className={styles.container}
			initial="closed"
			animate={isOpen ? 'open' : 'closed'}
			transition={{ duration: 0.5 }}
			variants={{
				open: { height: '100%' },
				closed: { height: '50px' }
			}}
		>
			<div role="toggle" onClick={() => setIsOpen(!isOpen)} className={styles.title}>
				<p>{isOpen ? <b>{title}</b> : title}</p>
				<motion.i
					initial="closed"
					className="ibon-chevron-up"
					animate={isOpen ? 'open' : 'closed'}
					transition={{ duration: 0.5 }}
					variants={{ open: { rotate: 0 }, closed: { rotate: -180 } }}
				/>
			</div>
			{children}
		</motion.div>
	);
};

export default Accordion;
