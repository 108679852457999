import React, { useState } from 'react';
import ReactGA from 'react-ga';
import styles from './ratearticle.module.css';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFeedbackArticle, selectArticleFeedback } from '../../store/articleSlice';
import { selectUser } from '../../store/userSlice';
import Loader from '../Loader/Loader';
import {EVENT} from "../../config/analytics";
import like from "./../../assets/images/like.svg";
import dislike from "./../../assets/images/dislike.svg";

const RateArticle = ({ article }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const customer = useSelector(selectUser);
	const feed = useSelector(selectArticleFeedback);
	const [rate, setRate] = useState(false);
	const [valueRate, setValueRate] = useState(false);
	const [sendValue, setSendValue] = useState(false);
	const [valueRadio, setValueRadio] = useState('');
	const [disabled, setDisabled] = useState(true);
	const [exit, setExit] = useState(false);

	const rateArticle = async value => {
		if (value) {
			ReactGA.event({category: EVENT.feedback_article.category, action: EVENT.feedback_article.actions.positive, label: article.name});
			setExit(true);
			dispatch(fetchFeedbackArticle({ articleUuid: article.uuid, customerUuid: customer.uuid, rate: value }));
		} else {
			ReactGA.event({category: EVENT.feedback_article.category, action: EVENT.feedback_article.actions.negative, label: article.name});
		}
		setRate(true);
		setValueRate(value);
	};

	const changeValue = value => {
		setValueRadio(value);
		if (value) {
			setDisabled(false);
		}
	};

	const sendValueFunc = async () => {
		ReactGA.event({category: EVENT.feedback_article.category, action: `${valueRadio}`, label: article.name});
		dispatch(
			fetchFeedbackArticle({
				articleUuid: article.uuid,
				customerUuid: customer.uuid,
				rate: false,
				comments: valueRadio
			})
		);
		setExit(true);
		setSendValue(true);
	};

	const goHome = () => {
		history.push('/');
	};

	return (
		<div className={'white_background'}>
			{feed.status === 'pending' && <Loader height={22} />}
			{feed.status !== 'pending' && (
				<>
					{!rate && (
						<div className={' snow_background'}>
							<p className="text-center m-y-16">
								<b>¿Fue útil esta información?</b>
							</p>
							<div className={styles.buttons}>
								<img src={like} alt="like" onClick={rateArticle.bind(this, true)}/>
								<img src={dislike} alt="dislike" onClick={rateArticle.bind(this, false)}/>
							</div>
						</div>
					)}
					{rate && valueRate && (
						<p className="m-y-16 text-center">
							<b>¡Gracias!</b>
						</p>
					)}
					{rate && !valueRate && (
						<div>
							{!sendValue && (
								<div>
									<div>
										<p className="text-center m-y-16">
											<b>¿Qué salió mal?</b>
										</p>
										<p onClick={changeValue.bind(this, 'No es lo que necesito')}>
											<input
												type="radio"
												id="test1"
												name="necesito"
												value="No es lo que necesito"
												checked={valueRadio === 'No es lo que necesito'}
												onChange={e => setValueRadio(e.target.value)}
												style={{ zIndex: 99999 }}
											/>
											<label htmlFor="test1" className={styles.reason}>
												No es lo que necesito
											</label>
										</p>

										<p className="m-y-16" onClick={changeValue.bind(this, 'La información no es clara')}>
											<input
												type="radio"
												id="test2"
												name="clara"
												value="La información no es clara"
												checked={valueRadio === 'La información no es clara'}
												onChange={e => setValueRadio(e.target.value)}
												style={{ zIndex: 99999 }}
											/>
											<label htmlFor="test2" className={styles.reason}>
												La información no es clara
											</label>
										</p>
									</div>
								</div>
							)}
							{!sendValue && (
								<button className="btn-outline-primary m-y-16" disabled={disabled} onClick={sendValueFunc.bind(this)}>
									Enviar
								</button>
							)}
							{sendValue && (
								<div className="text-center m-y-16">
									<b>¡Muchas gracias por tu ayuda!</b>
								</div>
							)}
						</div>
					)}

					{exit && (
						<button className="btn-outline-primary" onClick={goHome.bind(this)}>
							Volver al inicio
						</button>
					)}
				</>
			)}
		</div>
	);
};

export default RateArticle;
