import React, { useState } from 'react';
import styles from './rateticket.module.css';
import ReactGA from 'react-ga';
import { fetchFeedbackTicket, selectTicketRate } from '../../store/ticketSlice';
import { useDispatch, useSelector } from 'react-redux';

const Star = ({ value, rate, handleSelect }) => {
	return (
		<i
			className={`ibon-star ${styles.icon} ${rate > 0 && rate >= value ? styles.rateSelected : ''}`}
			onClick={() => handleSelect(value)}
		/>
	);
};

const RateTicket = ({ ticket_uuid }) => {
	const [rateTicket, setRateTicket] = useState(0);
	const [includeDetail, setIncludeDetail] = useState(false);
	const [submittedForm, setSubmittedForm] = useState(false);
	const [selectedValues, setSelectedValues] = useState([]);
	const dispatch = useDispatch();
	const ticketRate = useSelector(selectTicketRate);

	const handleSelectTicket = (value: number): void => {
		if (value < 5) {
			setIncludeDetail(true);
		} else {
			setIncludeDetail(false);
		}
		setRateTicket(value);
	};

	const handleInputChange = event => {
		const target = event.target;
		const isChecked = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		if (isChecked) {
			setSelectedValues([...selectedValues, { isChecked, name, value: target.value }]);
		} else {
			setSelectedValues(selectedValues.filter(item => item.name !== name));
		}
	};
	const onSubmit = async e => {
		e.preventDefault();
		const feedback = selectedValues.map(item => item.value).join(', ');

		ReactGA.event({
			category: 'feedback-ticket',
			action: `stars: ${rateTicket}`,
			label: `${feedback}`
		});

		await dispatch(
			fetchFeedbackTicket({
				ticket_rate: rateTicket,
				feedback: feedback,
				ticket_uuid: ticket_uuid
			})
		);
		setSubmittedForm(true);
	};

	const reasons = [
		{ value: 'Faltas de ortografía', name: 'detail1' },
		{ value: 'Mi asesor no fue claro', name: 'detail2' },
		{ value: 'Velocidad de respuesta', name: 'detail3' },
		{ value: 'No resolvieron mi problema', name: 'detail4' }
	];

	return (
		<div className={styles.container}>
			{!submittedForm && (
				<>
					<p className="text-center">
						<b>Queremos ofrecerte un servicio cada vez mejor. ¿Qué te pareció nuestra atención?</b>
					</p>
					<form onSubmit={onSubmit}>
						<div className="text-center m-y-16">
							{[1, 2, 3, 4, 5].map(value => (
								<Star key={value} value={value} rate={rateTicket} handleSelect={handleSelectTicket} />
							))}
						</div>
						<hr />
						{includeDetail && (
							<>
								<p className="text-center">
									<b>¿Qué estuvo mal?</b>
								</p>
								<ul className="text-center">
									{reasons.map(reason => {
										return (
											<li className={styles.checkbox} key={reason.name}>
												<input
													onChange={handleInputChange}
													hidden
													type="checkbox"
													name={reason.name}
													id={reason.name}
													value={reason.value}
												/>
												<label htmlFor={reason.name}>{reason.value}</label>
												<br />
											</li>
										);
									})}
								</ul>
							</>
						)}
						{rateTicket > 0 && (
							<div className={`m-16 ${styles.button}`}>
								<button disabled={ticketRate.status === 'pending'} className="btn-primary">
									Enviar
								</button>
							</div>
						)}
					</form>
				</>
			)}
			{submittedForm && <p>Gracias por tus sugerencias</p>}
		</div>
	);
};

export default RateTicket;
