import { Loader, ServerError } from '../index';
import React from "react";

export const PageContainer = ({ children, height = 80, showLoader, showError = false, showChildren}) => {

	return (
		<div className="m-16">
			{showLoader && <Loader height={height} />}
			{showError && <ServerError />}
			{showChildren && children}
		</div>
	);
};