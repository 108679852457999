export const toBase64 = file =>
	new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (typeof reader.result === 'string') {
				resolve(reader.result);
			}
		};
		reader.onerror = error => reject(error);
	});

export const fileListToBase64 = async ({ files }): Promise<Array<{ file: string; type: string; name: string }>> => {
	return await Promise.all(
		[...files].map(async file => {
			const fileBase64 = await toBase64(file);
			return { file: fileBase64, type: file.type, name: file.name };
		})
	);
};

export const removeDataInfoFromFileBase64 = (files = []) => {
	return files.map(file => {
		return { ...file, file: file.file.split(',')[1] };
	});
};
