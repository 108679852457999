import {buildBodyTicket, createMarkup} from "../../utils";
import {DynamicForm, HeaderBack} from "../../components";
import {removeDataInfoFromFileBase64} from "../../utils/files";
import {fetchCreateTicket, IRequestTicket, selectTicketCreated, setStatusTicketInitial} from "../../store/ticketSlice";
import React, {useEffect} from "react";
import {fetchGetScenario, selectScenario} from "../../store/scenarioSlice";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {selectArticle} from "../../store/articleSlice";
import {selectUser} from "../../store/userSlice";
import {PageContainer} from "../../components/PageContainer/PageContainer";
import {AppDispatch} from "../../store/store";
import {GuidelineRender} from "../../components/GuidelineRender/GuidelineRender";

export const ScenarioPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();
    const {item: scenario, status: statusScenario} = useSelector(selectScenario);
    const {item: article} = useSelector(selectArticle);
    const customer = useSelector(selectUser);
    const {status: creatingTicket} = useSelector(selectTicketCreated);
    const {scenarioUuid} = useParams<{ scenarioUuid: string }>();

    useEffect(() => {
        if (!article.uuid) {
            history.replace('/');
        }
    }, []);

    useEffect(() => {
        async function getData() {
            if (!scenario?.uuid) {
                await dispatch(fetchGetScenario(scenarioUuid));
            }

            await dispatch(setStatusTicketInitial());
        }

        getData();

    }, []);

    const handleSubmitForm = async (value) => {
        console.log({value})
        const fields = Object.keys(value).map(key => {
            const field = scenario.form.fields.find(field => field.name === key);
            if (field.type === 'file') {
                const files = removeDataInfoFromFileBase64(value[key]);
                if (files.length === 0) return;
                return {
                    id: field._id,
                    input: {
                        value: files.length === 1 ? files[0].file : files,
                        type: files.length === 1 ? files[0].type : field.type
                    }
                };
            }
            return {
                id: field._id,
                input: {value: value[key], type: field.type}
            };
        }).filter(n => n);

        const bodyRequest: IRequestTicket = buildBodyTicket({article, fields, customer, scenarioId: scenario.uuid});
        await dispatch(fetchCreateTicket({body: bodyRequest, scenario: scenario.title}));
    };

    return (
        <PageContainer
            showLoader={statusScenario === 'pending' || creatingTicket === 'pending'}
            showError={statusScenario === 'error'}
            showChildren={statusScenario === 'success' && (creatingTicket === 'initial' || creatingTicket === 'success')}
        >
            <HeaderBack title={scenario?.title} />
            <GuidelineRender html={scenario?.description} margin={0}/>
            <DynamicForm submit={handleSubmitForm} configForm={scenario?.form}/>
        </PageContainer>
    )
}