import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTicket, selectSelectedTicket } from '../../store/ticketSlice';
import { fetchComments, selectComments, selectLastComment } from '../../store/commetSlice';
import {event, pageview} from 'react-ga';
import {HeaderBack, RateTicket} from 'src/components';
import FormMessage from './components/FormMessage';
import Messages from './components/Messages';
import TicketInfo from './components/TicketInfo';
import {EVENT} from "../../config/analytics";
import {PageContainer} from "../../components/PageContainer/PageContainer";

const TicketDetailPage = () => {
	const dispatch = useDispatch();
	const ticket = useSelector(selectSelectedTicket);
	const messages = useSelector(selectComments);
	const lastMessages = useSelector(selectLastComment);
	const { ticket_uuid } = useParams<{ ticket_uuid: string }>();

	useEffect(() => {
		dispatch(fetchTicket(ticket_uuid));
		dispatch(fetchComments(ticket_uuid));
		pageview(`/ticket/${ticket_uuid}`);
		event({category: EVENT.navigation.category, action: EVENT.navigation.actions.ticket_detail});
	}, []);

	return (
		<>
			{(ticket?.status === 'error' || messages?.status === 'error' || lastMessages?.status === 'error') && (
				<Redirect to="/ticket-error" />
			)}
			<PageContainer
			showLoader={(ticket?.status === 'pending' || messages?.status === 'pending' || lastMessages?.status === 'pending')}
			height={70}
			showChildren={ticket?.status === 'success' && messages?.status === 'success' && lastMessages?.status !== 'pending'}
			>
				<HeaderBack title={ticket?.ticket?.title} />
				<TicketInfo ticket={ticket} />
				<Messages messages={messages} />
				{ticket.ticket.status === 'open' && <FormMessage ticket_uuid={ticket_uuid} />}
				{ticket.ticket.status === 'resolved' && !ticket.ticket.ticket_rate && (
					<RateTicket ticket_uuid={ticket.ticket.ticket_uuid} />
				)}
			</PageContainer>
		</>
	);
};

export default TicketDetailPage;
