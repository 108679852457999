import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchRefreshToken } from './userSlice';
import { getComments, sendComment } from '../http/ticketsService';
import {getFileUrlSigned} from "../utils";

export const fetchComments = createAsyncThunk('comments/fetchComments', async (ticket_uuid: string, thunkAPI) => {
	let response = await getComments(ticket_uuid);
	if (response.type === fetchRefreshToken.fulfilled.type) {
		response = await getComments(ticket_uuid);
	}
	response.data.files = await getFileUrlSigned(response);
	return response.data;
});

export const fetchSendComment = createAsyncThunk(
	'comments/fetchSendComments',
	async (
		{
			ticket_uuid,
			message,
			files
		}: { message: string; files: Array<{ type: string; file: string }>; ticket_uuid: string },
		thunkAPI
	) => {
		let response = await sendComment({ ticket_uuid, body: { message, files } });
		if (response.type === fetchRefreshToken.fulfilled.type) {
			response = await sendComment({ ticket_uuid, body: { message, files } });
		}
		thunkAPI.dispatch(fetchComments(ticket_uuid));
		return response;
	}
);

const commentsInit: IResponseComments = {
	comments: {
		status: 'initial',
		items: []
	},
	lastCommentAdded: {
		status: 'initial'
	}
};
const comments: IResponseComments = JSON.parse(sessionStorage.getItem('store'))?.comments ?? commentsInit;

export interface IComment {
	agent?: string;
	cdate: string;
	comment_formatted?: string;
	conversation: string;
	event: string;
	files: Array<{ type: string; file: string }>;
	ticket_uuid: string;
	type_comment: string;
}

export interface IListComments {
	comments: Array<IComment>;
	date: string;
}

export interface IResponseComments {
	comments: {
		status?: 'initial' | 'pending' | 'success' | 'error';
		items: Array<IListComments>
	};
	lastCommentAdded: {
		status?: 'initial' | 'pending' | 'success' | 'error';
	};
}

export const commentsSlice = createSlice({
	name: 'comments',
	initialState: comments,
	reducers: {
		clearComments: state => {
			state.comments.status = 'initial';
			state.comments.items = [];
		},
		pendingLastComment: (state, action: PayloadAction<'initial' | 'pending' | 'success' | 'error'>) => {
			state.lastCommentAdded.status = action.payload;
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchComments.fulfilled, (state, action: PayloadAction<{ comments: Array<IListComments> }>) => {
			state.comments.status = 'success';
			state.comments.items = action.payload.comments;
		});
		builder.addCase(fetchComments.pending, (state, action: PayloadAction<IResponseComments>) => {
			state.comments.status = 'pending';
		});
		builder.addCase(fetchComments.rejected, state => {
			state.comments.status = 'error';
		});

		builder.addCase(fetchSendComment.fulfilled, (state) => {
			state.lastCommentAdded.status = 'success';
		});

		builder.addCase(fetchSendComment.pending, (state) => {
			state.lastCommentAdded.status = 'pending';
		});

		builder.addCase(fetchSendComment.rejected, (state) => {
			state.lastCommentAdded.status = 'error';
		});
	}
});

// Action creators are generated for each case reducer function
export const { clearComments, pendingLastComment } = commentsSlice.actions;

export const selectComments = state => state.comments.comments;
export const selectLastComment = state => state.comments.lastCommentAdded;

export default commentsSlice.reducer;
