import React from 'react';
import Card from '../Card/Card';
import styles from './listcard.module.css';
import { ITaxonomy } from '../../store/articleSlice';

const ListCard = ({ items }: { items: Array<ITaxonomy> }) => {
	return (
		<ul aria-label="menu" className={styles.listCard}>
			{items.map(category => (
				<li key={category.name}>
					<Card category={category} />
				</li>
			))}
		</ul>
	);
};

export default ListCard;
