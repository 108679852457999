import Thumbnails from '../Thumbnails/Thumbnails';
import styles from './inputwiththumbnail.module.css';
import { Controller } from 'react-hook-form';
import { fileListToBase64 } from '../../utils/files';
import iconAdd from '../../assets/images/icon-add.svg';

const InputFile = ({ control, transform, name, validations, multiple = false }) => (
	<Controller
		control={control}
		name={name}
		rules={validations}
		render={({ field }) => (
			<>
				<input
					id={name}
					type="file"
					style={{ display: 'none' }}
					multiple={multiple}
					onChange={async e => {
						const files = await transform.output(e);
						field.onChange(files);
					}}
					value={transform.input(field.value)}
				/>
				<label htmlFor={name} className={`center ${styles.inputFile}`}>
					<img src={iconAdd} alt="agregar"/>
					<h3>Agregar evidencia</h3>
				</label>
			</>
		)}
	/>
);

const InputWhitThumbnail = ({
	name,
	files,
	setValue,
	control,
	validations,
	multiple
}: {
	name;
	files;
	setValue;
	control;
	validations?;
	multiple?;
}) => {
	const handleRemoveFile = index => {
		const newFiles = [...files].filter((_, i) => i !== index);
		setValue(name, newFiles);
	};

	return (
		<>
			<InputFile
				multiple={multiple}
				control={control}
				validations={validations}
				transform={{
					input: () => {
						return '';
					},
					output: async e => {
						return await fileListToBase64({ files: e.target.files });
					}
				}}
				name={name}
			/>
			<Thumbnails files={files} removeFile={handleRemoveFile} />
		</>
	);
};

export default InputWhitThumbnail;
