import { dateWithFormat } from '../../utils';
import styles from './listticket.module.css';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearTicketSelected } from '../../store/ticketSlice';
import { clearComments } from '../../store/commetSlice';

const ListTicket = ({ tickets }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const handleSelectTicket = async ticket => {
		await dispatch(clearTicketSelected());
		await dispatch(clearComments());
		history.push(`/ticket/${ticket.ticket_uuid}`);
	};

	return (
		<ul>
			{tickets.map(ticket => {
				return (
					<li key={ticket.ticket_uuid} className={styles.card}>
						<button onClick={() => handleSelectTicket(ticket)}>
							<p>
								<b>{ticket.title}</b>
							</p>
							<p>{dateWithFormat(ticket.cdate)}</p>
						</button>
					</li>
				);
			})}
		</ul>
	);
};

export default ListTicket;
